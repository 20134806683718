import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { Text, GradientBox } from 'shared/components/ui';
import { getName, getInitials } from 'shared/utils';
import { useGetCurrentUserQuery } from 'services';

import styles from './index.module.scss';

export const HeaderV2 = () => {
	const { data } = useGetCurrentUserQuery();

	return (
		<div className={styles.profileInfo}>
			<Link to="/profile" className={styles.link}>
				<div className={styles.profileAccount}>
					<GradientBox className={styles.gradientBox} backgroundClassName={styles.gradientBoxBefore}>
						<Avatar size={24} src={data?.avatar}>
							{getInitials(data)}
						</Avatar>
					</GradientBox>
					<Text variant="grotesk/14/regular" lineHeight={120} color="grey" className={styles.text}>
						{getName(data)}
					</Text>
				</div>
			</Link>
		</div>
	);
};
