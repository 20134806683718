import { ThemeConfig } from 'antd/es/config-provider/context';
import { notification } from 'antd';

import styles from './index.module.scss';

export const grey = '#d9d9d9';
export const darkGrey = '#868686';
export const darkGreen = '#006a37';
export const darkGreen20 = 'rgba(0, 106, 55, 0.2)';
export const green = '#66a687';
export const brightGreen = '#57d18a';
export const orange = '#ffa825';
export const lightBlue = '#1e78ff';
export const purple = '#a48bbe';
export const blue = '#1E78FF';

export const antTheme: ThemeConfig = {
	components: {
		Checkbox: {
			colorPrimary: blue,
			colorPrimaryHover: blue,
			colorBgBase: blue,
			controlInteractiveSize: 20,
			borderRadiusSM: 2,
			colorPrimaryBorder: darkGreen20,
			lineWidth: 0.5
		},
		Radio: {
			colorPrimary: brightGreen,
			lineWidth: 1.5
		},
		Select: {
			colorPrimary: blue,
			colorPrimaryHover: blue,
			controlOutline: darkGreen20,
			controlOutlineWidth: 0,
			controlItemBgActive: 'white',
			controlItemBgHover: '#fbfbfc',
			fontWeightStrong: 500,
			colorTextPlaceholder: darkGrey,
			borderRadiusLG: 1,
			borderRadiusSM: 1,
			borderRadius: 8,
			fontSize: 14,
			fontSizeLG: 14,
			fontSizeSM: 14
		},
		Upload: {
			borderRadiusLG: 4,
			colorFillAlter: '#ddebff',
			padding: 10,
			colorBorder: '#1e78ff',
			colorPrimaryHover: '#1e78ff'
		},
		Modal: {
			borderRadiusLG: 1,
			paddingMD: 0,
			paddingContentHorizontalLG: 0,
			colorBgMask: 'rgba(62, 62, 62, 0.26)'
		},
		Skeleton: {
			color: '#f3f3f3'
		},
		Tooltip: {
			borderRadius: 1,
			paddingSM: 8,
			paddingXS: 8,
			controlHeight: 22,
			sizePopupArrow: 12
		},
		Input: {
			colorTextPlaceholder: darkGrey
		},
		Wave: {
			colorPrimary: 'transparent'
		}
	}
};

notification.config({
	placement: 'bottom',
	bottom: 34,
	duration: 4,
	className: styles.popup
});
