import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';

import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
}

export const UpgradeModal: FC<Props> = ({ onClose }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/registration/plan');
	};

	return (
		<ConfigProvider theme={{ components: { Modal: { borderRadiusLG: 8 } } }}>
			<Modal
				destroyOnClose={true}
				centered={true}
				footer={null}
				closable={false}
				onCancel={onClose}
				width={899}
				zIndex={11000}
				open={true}>
				<div className={styles.container}>
					<div className={styles.wrapper}>
						<div className={styles.title}>
							<Text variant="inter/28/medium" color="dark_blue">
								To export more profiles, please upgrade to Prog.AI Pro
							</Text>
							<Text variant="inter/16/regular" color="dark_blue">
								You are currently on a Free Trial and can only export up to 100 profiles
							</Text>
						</div>
						<div className={styles.footer}>
							<Button type="ghost" onClick={onClose}>
								Cancel
							</Button>
							<Button type="secondary" onClick={handleClick}>
								Upgrade
							</Button>
						</div>
					</div>
					<div className={styles.image}>
						<Icon icon="close-big" size={20} className={styles.icon} onClick={onClose} />
					</div>
				</div>
			</Modal>
		</ConfigProvider>
	);
};
