import { Accordion, Text } from 'shared/components/ui';
import { questions } from 'features/subscription/utils';
import { PROGAI_EMAIL } from 'shared/utils';

import styles from './index.module.scss';

export const SubscriptionQA = () => {
	return (
		<section>
			<Text component="h2" variant="grotesk/32/bold" lineHeight={106} className={styles.title}>
				Questions &amp;&nbsp;Answers
			</Text>
			<div className={styles.questions}>
				{questions.map((question, idx) => (
					<Accordion
						className={styles.question}
						key={idx}
						title={
							<Text component="h3" variant="inter/20/bold">
								{question.question}
							</Text>
						}
						content={
							question.answer.includes('Install our Chrome extension') ? (
								<Text variant="inter/16/regular">
									Install our{' '}
									<a
										href="https://chromewebstore.google.com/detail/progai-in-depth-insights/pplkiigdcpbacikbalacopmaklichaod"
										target="_blank"
										rel="noreferrer">
										Chrome extension
									</a>
									, and you can see an additional panel on the right side of your browser. You will be
									able to see contacts when you browse user pages on GitHub. When you open a repo, you
									can see a list of contributors with their contacts info in our Chrome extension and
									bulk-export all contributors in a CSV or Google Sheet file.
								</Text>
							) : (
								<Text variant="inter/16/regular">{question.answer}</Text>
							)
						}
					/>
				))}
			</div>
			<Text component="p" variant="grotesk/16/regular" color="grey" className={styles.contact}>
				Can&rsquo;t find the answer here? <a href={`mailto:${PROGAI_EMAIL}`}>Contact&nbsp;us</a>
			</Text>
		</section>
	);
};
