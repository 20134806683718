import { SearchParams } from 'shared/generated-models';

export const parseFilters = (filters: SearchParams) => {
	let resultArray: string[] = [];

	if ('locations' in filters && Array.isArray(filters['locations'])) {
		resultArray = resultArray.concat(filters['locations']);
	}
	if ('countries' in filters && Array.isArray(filters['countries'])) {
		resultArray = resultArray.concat(filters['countries']);
	}
	if ('regions' in filters && Array.isArray(filters['regions'])) {
		resultArray = resultArray.concat(filters['regions']);
	}
	if ('sub_regions' in filters && Array.isArray(filters['sub_regions'])) {
		resultArray = resultArray.concat(filters['sub_regions']);
	}
	if ('likely_to_move_prob' in filters && filters['likely_to_move_prob']) {
		resultArray.push('Likely to move');
	}
	if ('seniority' in filters && Array.isArray(filters['seniority'])) {
		resultArray = resultArray.concat(filters['seniority']);
	}
	if ('yo_employment' in filters && Array.isArray(filters['yo_employment'])) {
		resultArray = resultArray.concat(filters['yo_employment']);
	}
	if ('yo_experience' in filters && Array.isArray(filters['yo_experience'])) {
		resultArray = resultArray.concat(filters['yo_experience']);
	}
	if ('companies' in filters && Array.isArray(filters['companies'])) {
		resultArray = resultArray.concat(filters['companies']);
	}
	if ('companies_to_exclude' in filters && Array.isArray(filters['companies_to_exclude'])) {
		resultArray = resultArray.concat(filters['companies_to_exclude']);
	}
	if ('required_skills' in filters && Array.isArray(filters['required_skills'])) {
		resultArray = resultArray.concat(filters['required_skills']);
	}
	if ('bonus_skills' in filters && Array.isArray(filters['bonus_skills'])) {
		resultArray = resultArray.concat(filters['bonus_skills']);
	}
	if ('prog_langs' in filters && Array.isArray(filters['prog_langs'])) {
		resultArray = resultArray.concat(filters['prog_langs']);
	}
	if ('github_repos' in filters && Array.isArray(filters['github_repos'])) {
		resultArray = resultArray.concat(filters['github_repos']);
	}
	if ('is_top_school' in filters && filters['is_top_school']) {
		resultArray.push('Top Schools');
	}
	if ('top_schools' in filters && Array.isArray(filters['top_schools'])) {
		resultArray = resultArray.concat(filters['top_schools']);
	}
	if ('languages' in filters && Array.isArray(filters['languages'])) {
		resultArray = resultArray.concat(filters['languages']);
	}
	if ('is_first_name_female' in filters && filters['is_first_name_female']) {
		resultArray.push('First name female');
	}

	return resultArray;
};
