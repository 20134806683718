import { createApi } from '@reduxjs/toolkit/query/react';

import { User, UserFull } from 'shared/generated-models';
import { interceptor } from 'services/interceptor';
import { environment } from 'environments/environment';

const redirectUrl = `${window.location.origin}/login`;
const errorUrl = `${window.location.origin}/login-error`;
export const authLink = `${environment.apiUrl}/login/google-oauth2?redirect_to=${redirectUrl}&error_to=${errorUrl}`;
export const microsoftAuthLink = `${environment.apiUrl}/login/microsoft-oauth2?redirect_to=${redirectUrl}&error_to=${errorUrl}`;

export const emptyUser: User = {
	id: 0,
	email: '',
	first_name: '',
	last_name: '',
	phone_number: '',
	company: '',
	avatar: '',
	billing_plan: '',
	credits: 0
};

export const authService = createApi({
	reducerPath: 'authService',
	tagTypes: ['User'],
	baseQuery: interceptor,
	endpoints: builder => ({
		getCurrentUser: builder.query<User, void>({
			query: () => '/users/current/',
			providesTags: ['User']
		}),
		updateUser: builder.mutation<User, User>({
			query: user => ({
				url: `/users/${user.id}/`,
				method: 'PUT',
				body: user
			}),
			invalidatesTags: ['User']
		}),
		logoutUser: builder.mutation<void, void>({
			query: () => ({ url: '/login/logout/', method: 'POST' }),
			invalidatesTags: ['User']
		}),
		getUserSubscription: builder.query<UserFull, void>({
			query: () => '/users/current/subscription/'
		})
	})
});

export const { useGetCurrentUserQuery, useUpdateUserMutation, useLogoutUserMutation, useGetUserSubscriptionQuery } =
	authService;
