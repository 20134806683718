import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'antd';
import cn from 'classnames';

import { Skill } from 'shared/generated-models';
import { FormikSelectV2, Icon } from 'shared/components/ui';
import { useDebounce, useOnClickOutside } from 'shared/hooks';

import styles from './index.module.scss';
import './add-tag.scss';

interface Props extends SelectProps {
	isOpen: boolean;
	className?: string;
	useQuery: any;
	name: string;
	label?: ReactNode;
	similar?: string[];
	icon: string;
}

export const SearchSelectV2: FC<Props> = ({
	isOpen,
	className,
	useQuery,
	name,
	label,
	similar,
	icon,
	...props
}: Props) => {
	const [field] = useField(name);
	const value = (field.value as []) || [];
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const [options, setOptions] = useState([]);

	useEffect(() => {
		dropdownRef.current?.children[0]?.children[0]?.scroll({ top: 0 });
	}, [options]);

	const isSkills = name === 'required_skills' || name === 'bonus_skills';

	const [searchQuery, setSearchQuery] = useState('');
	const debouncedSearchQuery = useDebounce(
		isSkills
			? searchQuery.replace(/\s+/g, ' ').replaceAll(' ', '-').trim()
			: searchQuery.replace(/\s+/g, ' ').trim(),
		300
	);

	const handleSearch = (search: string) => {
		setSearchQuery(search);
	};

	const ref = useRef(null);
	const [isFocused, setIsFocused] = useState(false);

	const handleFocusIn = () => {
		setIsFocused(true);
	};

	const handleFocusOut = () => {
		setIsFocused(false);
	};

	useOnClickOutside(ref, handleFocusOut);

	const { data } = useQuery(debouncedSearchQuery);

	useEffect(() => {
		setOptions(
			data?.map((item: Skill) => {
				return {
					label: item.name,
					value: item.name
				};
			}) || []
		);
	}, [data]);

	return (
		<div className={styles.wrapper}>
			<FormikSelectV2
				ref={ref}
				name={name}
				size="middle"
				onFocus={handleFocusIn}
				onBlur={handleFocusOut}
				dropdownRender={dropdown => <div ref={dropdownRef}>{dropdown}</div>}
				open={isFocused}
				value={value}
				menuItemSelectedIcon={null}
				onSearch={handleSearch}
				className={cn(
					styles.select,
					className,
					{ 'SearchSelect_add-tag': !isFocused && value.length !== 0 },
					{ [styles.selectOpen]: isOpen }
				)}
				mode="multiple"
				notFoundContent={null}
				filterOption={false}
				options={options}
				{...props}
			/>
			<Icon className={styles.icon} icon={icon} size={20} />
		</div>
	);
};
