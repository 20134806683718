import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import {
	SubscriptionCardsV2,
	SubscriptionQA,
	SubscriptionTestimonials,
	SubscriptionTrusted
} from 'features/subscription';
import { setHeaderTitle, setOpenPlans } from 'widgets/Header';

import styles from './index.module.scss';

export const RegistrationPlan = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setOpenPlans(true));

		return () => {
			dispatch(setOpenPlans(false));
		};
	}, []);

	useEffect(() => {
		dispatch(setHeaderTitle(''));
	}, [dispatch]);

	return (
		<main className={styles.container}>
			<SubscriptionCardsV2 />

			<SubscriptionTrusted />

			<SubscriptionQA />

			<SubscriptionTestimonials />
		</main>
	);
};
