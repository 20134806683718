import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	label: ReactNode;
	onClick: (arg: any) => void;
	className?: string;
}

export const Tag: FC<Props> = ({ label, onClick, className }: Props) => {
	return (
		<div className={cn(styles.tag, className)}>
			<Text variant="inter/14/medium" color="blue_new">
				{label}
			</Text>
			<button type="button" onClick={onClick}>
				<Icon icon="close" />
			</button>
		</div>
	);
};
