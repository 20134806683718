import { FC } from 'react';
import { Form } from 'antd';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { SearchFormV2, SearchSelectV2, SearchText } from 'features/search/components';
import { useGetCountriesQuery, useGetLocationsQuery } from 'services';
import { SearchFormParams } from 'features/search/models';
import { formIsEmpty } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	isOpen: boolean;
	handleClick: () => void;
}

export const SearchCard: FC<Props> = ({ isOpen, handleClick }: Props) => {
	const { values, setFieldValue, handleSubmit } = useFormikContext<SearchFormParams>();

	const buttonsDisabled = formIsEmpty({ ...values, size: undefined, page: undefined });

	const handleTextChange = (value: string) => {
		if (value.trim() === '') {
			setFieldValue('text', undefined);
		} else {
			setFieldValue('text', value);
		}
	};

	const handleShowCandidates = () => {
		if (buttonsDisabled) return;
		handleSubmit();
	};

	return (
		<Form layout="vertical" className={cn(styles.form, { [styles.formOpen]: isOpen })}>
			<div className={styles.sticky}>
				<div className={cn(styles.formWrapper, { [styles.formWrapperOpen]: isOpen })}>
					<div className={styles.inline}>
						<div className={styles.formTitle}>
							<Text variant="inter/22/medium">I am looking for...</Text>
						</div>
					</div>
					<div className={styles.section}>
						<SearchText isOpen={isOpen} value={values.text} onChange={handleTextChange} variant="main" />
						<div className={styles.row}>
							<SearchSelectV2
								isOpen={isOpen}
								label="City or state"
								name="locations"
								useQuery={useGetLocationsQuery}
								placeholder="City or state"
								icon="location"
							/>
							<SearchSelectV2
								isOpen={isOpen}
								label="Country"
								name="countries"
								useQuery={useGetCountriesQuery}
								placeholder="Country"
								icon="globe"
							/>
							<div
								onClick={handleShowCandidates}
								className={cn(styles.button, { [styles.buttonDisabled]: buttonsDisabled })}>
								<Text variant="inter/15/semi" color="white">
									Show Candidates
								</Text>
							</div>
						</div>
					</div>
				</div>
				<div
					className={cn(styles.filtersWrapper, { [styles.filtersWrapperOpen]: isOpen })}
					onClick={handleClick}>
					<div className={styles.filters}>
						<Icon icon="filter" size={20} className={styles.filtersIcon} />
						<Text variant="inter/15/medium" color="blue_new">
							All Filters
						</Text>
						<Icon
							icon="vector-down"
							size={12}
							className={cn(styles.filtersIcon, { [styles.filtersIconOpen]: isOpen })}
						/>
					</div>
				</div>
			</div>
			<div className={styles.open}>{isOpen && <SearchFormV2 />}</div>
		</Form>
	);
};
