import { Question } from 'features/subscription';

export const questions: Question[] = [
	{
		question: 'What is Prog.AI?',
		answer: "Prog.AI is a recruitment platform that helps startups and recruitment agencies search and hire top software developers by analyzing their code on GitHub. We build our own proprietary database of over 60 million software engineers worldwide, and our advanced AI search makes it easy to find the best candidates for your job description. We also have a proprietary outreach tool that helps you set up email outreach campaigns that actually work, using your company's email so candidates are more likely to open them."
	},
	{
		question: 'How do I search for candidates?',
		answer: 'First thing you see when you sign-in is a search panel. Enter job title, technical skills or any other keywords in plain English. Add filters such as location, years of experience, companies your preferred candidates used to work, schools they graduated from and other.\nYou can also search by skills: we have over 50,000 skills to choose from, or search for contributors to specific GitHub repos.'
	},
	{
		question: 'Are there any limitations?',
		answer: 'You can search and browse candidates profiles unlimitedly, and see candidates contacts info! The only limitation is on export: you can export up to 1,000 candidates profiles per month. If you need more, let us know!'
	},
	{
		question: 'Is there a free plan?',
		answer: 'Yes, we have a free plan for 14 days.'
	},
	{
		question: 'What are the different plans available?',
		answer: 'Our paid plan is $150/moth for unlimited access to the entire database of 60 million profiles. You can search and browse profiles unlimitedly, the only limitation is during export. You can also use our Chrome extension unlimitedly.'
	},
	{
		question: 'What is Likely-to-Move score?',
		answer: "Prog.AI team builds a proprietary score for each candidate predicting if they are more likely to change their job in the next 6 month. This prediction is based on LinkedIn work experience, global market trends (such as for example, middle level machine learning engineers are more likely to move in current market, and companies' rating from Indeed.com)"
	},
	{
		question: 'How to search engineers on GitHub?',
		answer: 'Install our Chrome extension, and you can see an additional panel on the right side of your browser. You will be able to see contacts when you browse user pages on GitHub. When you open a repo, you can see a list of contributors with their contacts info in our Chrome extension and bulk-export all contributors in a CSV or Google Sheet file.'
	}
	// {
	// 	question: 'Why use Prog.AI?',
	// 	answer:
	// 		'Here are some of the benefits of using Prog.AI:\n' +
	// 		'\n' +
	// 		'Our AI analyses every commit on open source projects on GitHub as well as broad and unique data points to effectively identify and hire top software engineers across more than 50k skills.\n' +
	// 		'\n' +
	// 		'Large database of candidates: Prog.AI has a database of more than 60 million candidates, making it easy to find the right person for your team.\n' +
	// 		'\n' +
	// 		"Powerful search: Prog.AI's AI search technology makes it easy to find candidates with the specific skills and technologies you're looking for."
	// },
	// {
	// 	question: 'What is a credit on Prog.AI?',
	// 	answer: 'A credit is an ability to download candidates. For example, if you have 100 credits, you can download a CSV file with 100 candidates and their contact information.'
	// }
];
