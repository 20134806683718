import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	label: ReactNode;
	onClick: (arg: any) => void;
	className?: string;
	hideCross?: boolean;
	inverted?: boolean;
	handleClick?: () => void;
}

export const TagV2: FC<Props> = ({ label, onClick, className, hideCross, inverted, handleClick }: Props) => {
	return (
		<div className={cn(styles.tag, className, { [styles.tagInverted]: inverted })} onClick={handleClick}>
			<Text variant="inter/14/medium" color="blue_new">
				{label}
			</Text>
			{!hideCross && (
				<button type="button" onClick={onClick}>
					<Icon icon="close" />
				</button>
			)}
		</div>
	);
};
