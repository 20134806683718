import React, { FC, memo } from 'react';
import cn from 'classnames';

import { OpenedContactShort } from 'shared/generated-models';
import { TableSelectionMode, Tr } from 'features/table';
import { Checkbox, Icon, LikelyToMoveTag, RockstarTag, Text } from 'shared/components/ui';
import { formatDate, getProfileInitials, MONTH_DAY_YEAR_FORMAT } from 'shared/utils';
import { CandidateProfileAvatar, ProfileName } from 'shared/components';
import { formatLocationShort, formatTitle } from 'features/search/utils';
import { ProfileContacts } from 'features/search';

import styles from './index.module.scss';

interface Props {
	candidate: OpenedContactShort;
	className?: string;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
	variant?: 'acquired' | 'project';
	onClick: (contact: OpenedContactShort) => void;
}

export const CandidateRow: FC<Props> = memo(({ candidate, className, checkbox, onClick, variant }: Props) => {
	const handleClick = () => {
		onClick(candidate);
	};

	const title = formatTitle(candidate);
	const location = formatLocationShort(candidate.location, candidate.country);
	const isRockstar = candidate.seniority_level === 'Rock Star';

	return (
		<Tr className={cn(styles.profile, className)}>
			<button className={styles.button} onClick={handleClick} />
			<div className={styles.border}></div>
			<div className={styles.wrapper}>
				<Checkbox className={styles.profileCheckbox} checked={checkbox.checked} onChange={checkbox.onChange} />
				<CandidateProfileAvatar
					onClick={handleClick}
					src={candidate.profile_pic_url}
					size={80}
					className={styles.profileAvatar}>
					{getProfileInitials(candidate)}
				</CandidateProfileAvatar>
				<div className={styles.profileSummary}>
					<div className={styles.profileHeader}>
						<ProfileName variant="inter/16/semi" profile={candidate} className={styles.profileName} />
						{variant === 'project' && (
							<div className={styles.profileHeaderRight}>
								<LikelyToMoveTag prob={candidate.likely_to_move_prob} />
								{isRockstar && <RockstarTag />}
								<ProfileContacts candidate={candidate} />
							</div>
						)}
					</div>
					<div className={styles.profileBody}>
						<div className={styles.profileTitleWrapper}>
							{title && (
								<Text variant="inter/13/medium" className={styles.profileTitle}>
									{title}
								</Text>
							)}
							<Text variant="inter/13/regular" color="darker_grey">
								{candidate.years_of_experience &&
									(candidate.years_of_experience > 10
										? `10+ y experience`
										: `${candidate.years_of_experience} y experience`)}
							</Text>
						</div>
						<div className={styles.row}>
							<Icon icon="location" size={20} className={styles.iconLocation} />
							<Text variant="inter/13/regular" color="darker_grey">
								{location ? location : 'N/A'}
							</Text>
						</div>
					</div>
					{variant === 'acquired' && (
						<div className={styles.profileFooter}>
							<div className={styles.profileFooterIcons}>
								<a
									className={styles.iconGithub}
									href={candidate.github_url}
									target="_blank"
									rel="noreferrer">
									<Icon icon="github-logo" size={20} />
								</a>
								{candidate.linkedin_url && (
									<a
										className={styles.iconLinkedin}
										href={candidate.linkedin_url}
										target="_blank"
										rel="noreferrer">
										<Icon icon="linkedin-logo" size={20} />
									</a>
								)}
								{candidate.twitter_url && (
									<a href={candidate.twitter_url} target="_blank" rel="noreferrer">
										<Icon icon="twitter-logo" size={20} />
									</a>
								)}
								{candidate.stackoverflow_url && (
									<a href={candidate.stackoverflow_url} target="_blank" rel="noreferrer">
										<Icon icon="stackoverflow-logo" size={20} />
									</a>
								)}
								{candidate.homepage_url && (
									<a
										className={styles.iconWebsite}
										href={candidate.homepage_url}
										target="_blank"
										rel="noreferrer">
										<Icon icon="website" size={20} />
									</a>
								)}
								<Icon className={styles.iconMail} icon="mail" size={20} />
								<a className={styles.contactsLink} href={`mailto:${candidate.primary_email}`}>
									<Text
										variant="inter/14/medium"
										color="light_blue"
										className={styles.contactsMailIcon}>
										{candidate.primary_email}
									</Text>
								</a>
							</div>
							<div>
								<Text variant="inter/13/regular" color="grey">
									Added on {formatDate(candidate.added_at, MONTH_DAY_YEAR_FORMAT)} / Expires&nbsp;
								</Text>
								<Text variant="inter/13/medium" color="darker_grey">
									{formatDate(candidate.expires_at, MONTH_DAY_YEAR_FORMAT)}
								</Text>
							</div>
						</div>
					)}
				</div>
			</div>
		</Tr>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.checkbox.checked === newProps.checkbox.checked && oldProps.checkbox.mode === newProps.checkbox.mode;
}
