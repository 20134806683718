import { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { RecentSearch, SearchCard, TrendingSearch } from 'features/search/components';
import { useSearch } from 'features/search/hooks';
import { SearchFormParams } from 'features/search/models';
import { ChromeExtensionModal } from 'features/auth';

import styles from './index.module.scss';

export const MainSearchFormV2 = () => {
	const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
	const search = useSearch();

	const formik = useFormik<SearchFormParams>({
		initialValues: {},
		validateOnMount: true,
		onSubmit: values => {
			search({ ...values, page: 0 });
		}
	});

	const handleFilters = () => {
		setFiltersOpen(!filtersOpen);
	};

	return (
		<>
			<FormikProvider value={formik}>
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<SearchCard isOpen={filtersOpen} handleClick={handleFilters} />
						<div className={styles.row}>
							<TrendingSearch />
							<RecentSearch />
						</div>
					</div>
				</div>
			</FormikProvider>
			<ChromeExtensionModal />
		</>
	);
};
