import { FC } from 'react';
import cn from 'classnames';

import { useGetProjectsListQuery } from 'services';
import { TableSelection, useTableSelection } from 'features/table';
import { CreateProjectButton, SidebarMenu, ProjectRow, ProjectsListAcquired } from 'features/projects';
import { Project } from 'shared/generated-models';
import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const ProjectsSidebar: FC<Props> = ({ className }: Props) => {
	const { data } = useGetProjectsListQuery();

	const { isAllSelected, toggle, isSelected, count, togglePage, includedItems, someSelected, clearSelection, mode } =
		useTableSelection<Project>({
			data: data?.projects,
			totalCount: data?.projects_count
		});

	return (
		<div className={cn(styles.container, className)}>
			<div className={styles.header}>
				<TableSelection
					className={styles.headerSelection}
					controls={{ main: { togglePage, isAllSelected, count } }}
					hint="Select projects"
				/>
				<div>
					<CreateProjectButton className={styles.create}>
						<Icon icon="add-folder" />
					</CreateProjectButton>
					<SidebarMenu disabled={!someSelected} projects={includedItems} onDelete={clearSelection} />
				</div>
			</div>
			<div className={styles.list}>
				{/* <ProjectsListAcquired /> */}
				{data?.projects.map(project => (
					<ProjectRow
						checkbox={{
							checked: isSelected({ row: project }),
							onChange: () => toggle({ row: project }),
							mode
						}}
						project={project}
						key={project.id}
					/>
				))}
			</div>
		</div>
	);
};
