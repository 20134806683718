import { ConfigProvider, Tooltip } from 'antd';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface LikelyToMoveTagProps {
	prob?: number;
	showPercentage?: boolean;
	onProfile?: boolean;
}

interface MatchProps {
	score: string;
	onProfile?: boolean;
}

interface RockstarProps {
	onProfile?: boolean;
}

export const LikelyToMoveTag = ({ prob, showPercentage, onProfile }: LikelyToMoveTagProps) => {
	if (!prob) return null;

	if (prob < 0.6) return null;

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Tooltip
				arrow={false}
				title={
					<div className={styles.tooltipTitle}>
						<Text color="dark_green">
							Prog.AI’ algorithm predicts this candidate will probably change their job in the next 6
							months
						</Text>
					</div>
				}
				mouseEnterDelay={onProfile ? 0 : 1}
				overlayClassName={styles.tooltip}
				placement="bottom">
				<Text
					variant={onProfile ? 'inter/14/medium' : 'inter/13/medium'}
					color="black"
					className={cn(styles.highlight, styles.highlightLikely)}>
					<Icon icon="likely-to-move" size={20} />
					Likely to move
					{showPercentage && (
						<div className={styles.highlightPercentage}>
							<Text variant="inter/15/semi" color="white">
								{Math.ceil(prob * 100)}%
							</Text>
						</div>
					)}
				</Text>
			</Tooltip>
		</ConfigProvider>
	);
};

export const MatchTag = ({ score, onProfile }: MatchProps) => {
	const normalizedScore = score === "0%" ? '2%' : score;
	return (
		<Text
			variant={onProfile ? 'inter/14/medium' : 'inter/13/medium'}
			className={cn(styles.highlight, styles.highlightMatch)}>
			<Icon icon="match-check" size={20} />
			{`${normalizedScore} match`}
		</Text>
	);
};

export const RockstarTag = ({ onProfile }: RockstarProps) => {
	return (
		<Text variant={onProfile ? 'inter/14/medium' : 'inter/13/medium'} color="black" className={styles.wrapper}>
			<div className={styles.emoji}>🤩</div>
			Rockstar
		</Text>
	);
};
