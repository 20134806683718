import { useEffect, useState } from 'react';
import { ConfigProvider, Form, Modal } from 'antd';
import { useFormik, FormikProvider } from 'formik';
import cn from 'classnames';

import { Icon, TagV2, Text } from 'shared/components/ui';
import { SearchFormV2, SearchSelectV2, SearchText } from 'features/search/components';
import { useGetCountriesQuery, useGetLocationsQuery } from 'services';
import { SearchParams } from 'shared/generated-models';
import { formIsEmpty } from 'shared/utils';
import { useFilters, useSearch } from 'features/search/hooks';
import { parseFilters } from 'shared/utils';

import styles from './index.module.scss';

const MAX_TAGS_TO_SHOW = 5;

function deepEqual(obj1: any, obj2: any) {
	if (obj1 === obj2) return true;
	if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) return false;
	for (let key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}
	return true;
}

export const SideSearchCard = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [tags, setTags] = useState<string[]>([]);

	const [isAnimating, setIsAnimating] = useState(false);

	const filters = useFilters();
	const search = useSearch();

	const formik = useFormik<SearchParams>({
		initialValues: filters,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: values => {
			search({ ...values, page: 0 }, { refresh: true });
		}
	});
	const { values, setFieldValue, handleSubmit } = formik;

	useEffect(() => {
		const tags: string[] = parseFilters(values);
		setTags(tags);
	}, [values]);

	const formHasValue = !formIsEmpty({ ...formik.values, size: undefined, page: undefined });

	const handleClick = () => {
		if (!isOpen) {
			setTimeout(() => setIsAnimating(true), 50);
			setIsOpen(true);
		} else {
			setIsAnimating(false);

			setTimeout(() => setIsOpen(false), 200);
		}

		if (!deepEqual(values, filters)) {
			handleSubmit();
		}
	};

	const openModal = () => {
		setTimeout(() => setIsAnimating(true), 50);
		setIsOpen(true);
	};

	const modalStyle = {
		transform: isAnimating ? 'translateY(0)' : 'translateY(-100vh)',
		opacity: isAnimating ? 1 : 0,
		transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
		marginLeft: '180px',
		paddingLeft: '90px',
		paddingRight: '90px',
		borderRadius: '16px'
	};

	const handleTextChange = (value: string) => {
		if (value.trim() === '') {
			setFieldValue('text', undefined);
		} else {
			setFieldValue('text', value);
		}
	};

	const handleShowCandidates = () => {
		if (!formHasValue) return;
		handleSubmit();
		setIsAnimating(false);
		setTimeout(() => setIsOpen(false), 200);
	};

	const clearFilters = () => {
		formik.setValues({});
		openModal();
	};

	return (
		<FormikProvider value={formik}>
			<Form layout="vertical" className={styles.formNew}>
				<SearchText
					isOpen={isOpen}
					value={values.text}
					onChange={handleTextChange}
					openModal={openModal}
					variant="side"
					suffix={
						<div className={styles.filters} onClick={handleClick}>
							<Icon icon="filter" size={20} className={styles.filtersIcon} />
							<Text variant="inter/15/medium" color="blue_new">
								All Filters
							</Text>
							<Icon icon="vector-down" size={12} className={styles.filtersIcon} />
						</div>
					}
				/>
				{formHasValue && (
					<div className={styles.formNewFooter}>
						<div className={styles.row}>
							{tags.slice(0, MAX_TAGS_TO_SHOW).map(tag => (
								<TagV2 label={tag} onClick={() => {}} hideCross={true} />
							))}
							{tags.length > MAX_TAGS_TO_SHOW && (
								<TagV2
									label={`More(${tags.length - MAX_TAGS_TO_SHOW})`}
									onClick={() => {}}
									handleClick={openModal}
									inverted={true}
									hideCross={true}
								/>
							)}
						</div>
						<span onClick={clearFilters} className={styles.formNewFooterClear}>
							<Text variant="inter/15/medium" color="blue_new">
								Clear all
							</Text>
						</span>
					</div>
				)}
			</Form>
			<ConfigProvider theme={{ components: { Modal: { zIndexPopupBase: 10001 } } }}>
				<Modal
					width="calc(100% - 180px)"
					style={modalStyle}
					bodyStyle={{
						height: 'calc(100vh - 100px)',
						overflow: 'auto',
						borderRadius: '16px !important'
					}}
					className={styles.modal}
					open={isOpen}
					destroyOnClose={true}
					onCancel={handleClick}
					footer={null}
					transitionName=""
					closable={false}>
					<Form layout="vertical">
						<div className={cn(styles.form, styles.formOpen)}>
							<div className={cn(styles.formWrapper, styles.formWrapperOpen)}>
								<div className={styles.section}>
									<SearchText
										isOpen={isOpen}
										value={values.text}
										onChange={handleTextChange}
										variant="modal"
										suffix={
											<div className={styles.filters} onClick={handleClick}>
												<Icon icon="filter" size={20} className={styles.filtersIcon} />
												<Text variant="inter/15/medium" color="blue_new">
													Hide Filters
												</Text>
												<Icon
													icon="vector-down"
													size={12}
													className={cn(styles.filtersIcon, styles.filtersIconOpen)}
												/>
											</div>
										}
									/>
									<div className={styles.row} style={{ marginTop: 8 }}>
										<SearchSelectV2
											isOpen={isOpen}
											label="City or state"
											name="locations"
											useQuery={useGetLocationsQuery}
											placeholder="City or state"
											icon="location"
										/>
										<SearchSelectV2
											isOpen={isOpen}
											label="Country"
											name="countries"
											useQuery={useGetCountriesQuery}
											placeholder="Country"
											icon="globe"
										/>
										<div
											onClick={handleShowCandidates}
											className={cn(styles.button, {
												[styles.buttonDisabled]: !formHasValue
											})}>
											<Text variant="inter/15/semi" color="white">
												Show Candidates
											</Text>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.open}>
								<SearchFormV2 />
							</div>
						</div>
					</Form>
				</Modal>
			</ConfigProvider>
		</FormikProvider>
	);
};
