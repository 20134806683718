import { FC, ReactNode, useState } from 'react';

import DownloadLogo from 'shared/assets/svg-icons/download.svg';
import SheetsLogo from 'shared/assets/svg-icons/sheets.svg';
import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import SourceWhaleLogo from 'shared/assets/svg-icons/source-whale-logo.svg';
import { Text, Dropdown, SkeletonRow } from 'shared/components/ui';
import { ExportEnum, ServiceEnum, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { useModalState } from 'shared/hooks/use-modal-state';
import { useConnectedServices } from 'features/integrations/hooks';
import { PushConfirmModalState } from 'features/integrations/models';
import { ExportCsvModal, ExportGoogleSheetsModal } from 'features/csv-export';
import { ContactUsModal, NoCreditsModal, UpgradeModal } from 'features/auth/components';

import { SelectLemlistCampaignModal } from '../SelectLemlistCampaignModal';
import { SelectSourceWhaleCampaignModal } from '../SelectSourceWhaleCampaignModal';
import { IntegrationsModal } from '../IntegrationsModal';
import { PushConfirmModal } from '../PushConfirmModal';
import { useGetCurrentUserQuery } from 'services';
import { useIsFree } from 'features/auth';

import styles from './index.module.scss';

interface Props {
	children?: ReactNode;
	projectId?: number;
	candidates: TopIdsWithFiltersCandidates & {
		count: number;
	};
	disabled?: boolean;
	handleExportCsv: (isFull: boolean) => void;
	handleExportGoogleSheets: (isFull: boolean) => void;
}

export const ExportToPushMenu: FC<Props> = ({
	children,
	projectId,
	candidates,
	disabled,
	handleExportCsv,
	handleExportGoogleSheets
}) => {
	const { data, isLoading } = useConnectedServices();
	const { data: user } = useGetCurrentUserQuery();

	const isFree = useIsFree();

	const [upgradeModal, setUpgradeModal] = useState(false);
	const [contactUsModal, setContactUsModal] = useState(false);

	const menuDropdown = useModalState();
	const exportToGoogleSheetsModal = useModalState<ExportEnum>();
	const exportToEmailModal = useModalState<ExportEnum>();

	const closeUpgradeModal = () => {
		setUpgradeModal(false);
	}

	const closeContactUsModal = () => {
		setContactUsModal(false);
	}

	const openGoogleSheetsModal = () => {
		if (isFree && user && user.credits < candidates.count) {
			setUpgradeModal(true);
			menuDropdown.close();
			return;
		}
		if (!isFree && user && user.credits < candidates.count) {
			setContactUsModal(true);
			menuDropdown.close();
			return;
		}
		exportToGoogleSheetsModal.open();
		menuDropdown.close();
	};

	const openCsvModal = () => {
		if (isFree && user && user.credits < candidates.count) {
			setUpgradeModal(true);
			menuDropdown.close();
			return;
		}
		if (!isFree && user && user.credits < candidates.count) {
			setContactUsModal(true);
			menuDropdown.close();
			return;
		}
		exportToEmailModal.open();
		menuDropdown.close();
	};

	const connectIntegrationsModal = useModalState<ServiceEnum>();
	const selectCampaignModal = useModalState<ServiceEnum>();
	const pushConfirmModal = useModalState<PushConfirmModalState>();

	return (
		<>
			<Dropdown
				disabled={disabled}
				trigger={['click']}
				placement="bottomRight"
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<div className={styles.dropdownList}>
							{isLoading ? (
								<SkeletonRow height={42} />
							) : (
								<>
									<button className={styles.button} onClick={openCsvModal}>
										<img className={styles.logo} src={DownloadLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											CSV file
										</Text>
									</button>
									<button className={styles.button} onClick={openGoogleSheetsModal}>
										<img className={styles.logo} src={SheetsLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											Google Sheet
										</Text>
									</button>
									<button
										className={styles.button}
										onClick={() => {
											if (isFree && user && user.credits < candidates.count) {
												setUpgradeModal(true);
												menuDropdown.close();
												return;
											}
											if (!isFree && user && user.credits < candidates.count) {
												setContactUsModal(true);
												menuDropdown.close();
												return;
											}
											if (data.includes(ServiceEnum.LEMLIST)) {
												selectCampaignModal.open(ServiceEnum.LEMLIST);
											} else {
												connectIntegrationsModal.open(ServiceEnum.LEMLIST);
											}

											menuDropdown.close();
										}}>
										<img className={styles.logo} src={LemlistLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											Lemlist
										</Text>
										{!data.includes(ServiceEnum.LEMLIST) && (
											<Text variant="inter/14/medium" className={styles.connect}>
												Connect
											</Text>
										)}
									</button>
									<button
										className={styles.button}
										onClick={() => {
											if (isFree && user && user.credits < candidates.count) {
												setUpgradeModal(true);
												menuDropdown.close();
												return;
											}
											if (!isFree && user && user.credits < candidates.count) {
												setContactUsModal(true);
												menuDropdown.close();
												return;
											}
											if (data.includes(ServiceEnum.SOURCEWHALE)) {
												selectCampaignModal.open(ServiceEnum.SOURCEWHALE);
											} else {
												connectIntegrationsModal.open(ServiceEnum.SOURCEWHALE);
											}

											menuDropdown.close();
										}}>
										<img className={styles.logo} src={SourceWhaleLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											SourceWhale
										</Text>
										{!data.includes(ServiceEnum.SOURCEWHALE) && (
											<Text variant="inter/14/medium" className={styles.connect}>
												Connect
											</Text>
										)}
									</button>
								</>
							)}
						</div>
					</div>
				)}
				onOpenChange={open => (open ? menuDropdown.open() : menuDropdown.close())}
				open={menuDropdown.isOpen}>
				{children}
			</Dropdown>

			{connectIntegrationsModal.isOpen && (
				<IntegrationsModal
					onClose={connectIntegrationsModal.close}
					onConnected={() => {
						selectCampaignModal.open(connectIntegrationsModal.data);
						connectIntegrationsModal.close();
					}}
				/>
			)}
			{selectCampaignModal.isOpen &&
				(selectCampaignModal.data === ServiceEnum.LEMLIST ? (
					<SelectLemlistCampaignModal
						onClose={selectCampaignModal.close}
						onSelect={result => {
							selectCampaignModal.close();
							pushConfirmModal.open({
								service: ServiceEnum.LEMLIST,
								result
							});
						}}
					/>
				) : (
					<SelectSourceWhaleCampaignModal
						onClose={selectCampaignModal.close}
						onSelect={result => {
							selectCampaignModal.close();
							pushConfirmModal.open({
								service: ServiceEnum.SOURCEWHALE,
								result
							});
						}}
					/>
				))}
			{pushConfirmModal.isOpen && (
				<PushConfirmModal
					onBack={() => {
						selectCampaignModal.open(pushConfirmModal.data?.service);
						pushConfirmModal.close();
					}}
					onClose={pushConfirmModal.close}
					projectId={projectId}
					candidates={candidates}
					{...pushConfirmModal.data!}
				/>
			)}

			{exportToGoogleSheetsModal.isOpen && (
				<ExportGoogleSheetsModal
					isFree={isFree}
					handleExport={handleExportGoogleSheets}
					onClose={() => exportToGoogleSheetsModal.close()}
					candidates={candidates}
				/>
			)}

			{exportToEmailModal.isOpen && (
				<ExportCsvModal
					isFree={isFree}
					handleExport={handleExportCsv}
					onClose={() => exportToEmailModal.close()}
					candidates={candidates}
				/>
			)}
			{/* {notEnoughCreditsModalOpen && <NoCreditsModal onClose={closeModal} />} */}
			{upgradeModal && <UpgradeModal onClose={closeUpgradeModal} />}
			{contactUsModal && <ContactUsModal onClose={closeContactUsModal} isExpiring={false}/>}
		</>
	);
};
