import { Outlet } from 'react-router-dom';

import { Navbar } from 'shared/components';

import styles from './index.module.scss';

export const MainLayout = () => {
	return (
		<div className={styles.layout}>
			<Navbar className={styles.sidebar} />
			<main className={styles.content}>
				<Outlet />
			</main>
		</div>
	);
};
