import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';

import { Button, Icon, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { PROGAI_EMAIL } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
	isExpiring: boolean;
}

export const ContactUsModal: FC<Props> = ({ onClose, isExpiring }: Props) => {
	const { data } = useGetCurrentUserQuery();
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/registration/plan');
	};

	return (
		<ConfigProvider theme={{ components: { Modal: { borderRadiusLG: 8 } } }}>
			<Modal
				destroyOnClose={true}
				centered={true}
				footer={null}
				closable={false}
				onCancel={onClose}
				width={899}
				zIndex={11000}
				open={true}>
				<div className={styles.container}>
					<div className={styles.wrapper}>
						<div className={styles.title}>
							{data && (
								<Text variant="inter/28/medium" color="dark_blue">
									You have {data.credits} credits left
								</Text>
							)}
							{isExpiring ? (
								<Text variant="inter/16/regular" color="dark_blue">
									You are about to exceed your monthly export limit
								</Text>
							) : (
								<Text variant="inter/16/regular" color="dark_blue">
									You have exceeded your monthly export limit
								</Text>
							)}
							<Text variant="inter/16/semi" color="dark_blue">
								To export more profiles, please contact us
							</Text>
						</div>
						<div className={styles.footer}>
							<Button type="ghost" onClick={onClose}>
								Cancel
							</Button>
							<Button
								type="primary"
								component="a"
								href={`mailto:${PROGAI_EMAIL}?subject=Request%20for%20an%20extended%20data%20export&body=I’ve%20exported%20about%201000%20profiles%20this%20month%20but%20I%20need%20to%20export%20more.%20Could%20you%20assist%20me%20with%20adding%20more%20credits%20to%20my%20account%3F`}>
								Contact us
							</Button>
						</div>
					</div>
					<div className={styles.image}>
						<Icon icon="close-big" size={20} className={styles.icon} onClick={onClose} />
					</div>
				</div>
			</Modal>
		</ConfigProvider>
	);
};
