import { User } from 'shared/generated-models';

export const getFullName = (user?: User) => {
	if (!user) return '';
	return `${user.first_name} ${user.last_name}`;
};

export const getName = (user?: User) => {
	if (!user) return '';
	return user.first_name;
};
