import { FormikValues } from 'formik';

export const formIsEmpty = (values: FormikValues) => {
	const cleanValues = { ...values };
	if ('is_current_companies' in cleanValues) delete cleanValues['is_current_companies'];
	if ('is_current_companies_to_exclude' in cleanValues) delete cleanValues['is_current_companies_to_exclude'];
	return Object.values(cleanValues).every(
		cleanValues => !cleanValues || (Array.isArray(cleanValues) && cleanValues.length === 0)
	);
};
